/* eslint-disable indent */
import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

// enforce that the key must be boolean or undefined, which will coerce to false
type AppEnvironmentKey = keyof {
	[P in keyof AppEnvironment as AppEnvironment[P] extends boolean | undefined ? P : never]: never;
};

@Injectable({
	providedIn: 'root'
})
export class AppEnvironmentGuard {
	constructor(
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment,
		private router: Router
	) {}

	public canActivate(envProps: AppEnvironmentKey[]): boolean {
		const isTrue = envProps.every((prop) => this.appEnv[prop]);
		if (isTrue) {
			return true;
		}
		this.router.navigate(['/']);
		return false;
	}
}

/**
 * This is a partially applied function that allows us to provide arguments to our canActivate tokens
 * @param envProps - any boolean property in AppEnvironment
 */
export const appEnvironmentGuard =
	(envProps: AppEnvironmentKey[]): CanActivateFn =>
	(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
		return inject(AppEnvironmentGuard).canActivate(envProps);
	};
